<template>
  <div class="details">
    <steps :stepInfo="stepInfo"></steps>
    <div class="lsitads" v-if="isorder != '1' && !oderItem.ispinglun">
      <div class="detalet">
        <div class="tops">订单信息</div>
        <div class="leftbottom">
          <div class="ifolist">
            <div class="name">物流信息：</div>
            <div class="zhangsna">
              {{ oderItem.order.consigneeName }},{{
                oderItem.order.consigneeMobile
              }},{{ oderItem.order.consigneeAddressPath }},{{
                oderItem.order.consigneeDetail
              }}
            </div>
          </div>
          <div class="ifolist">
            <div class="name">买家留言：</div>
            <div class="zhangsna">{{ oderItem.order.remark || "/" }}</div>
          </div>
          <div class="ifolist">
            <div class="name">订单编号：</div>
            <div class="zhangsna">{{ oderItem.order.sn }}</div>
          </div>
          <div class="ifolist">
            <div class="name">配送方式：</div>
            <div class="zhangsna">物流</div>
          </div>
          <div class="ifolist">
            <div class="name">订单状态</div>
            <div class="zhangsna" v-if="oderItem.order.orderStatus == 'UNPAID'">
              未付款
            </div>
            <div class="zhangsna" v-if="oderItem.order.orderStatus == 'PAID'">
              已付款
            </div>
            <div
              class="zhangsna"
              v-if="oderItem.order.orderStatus == 'UNDELIVERED'"
            >
              待发货
            </div>
            <div
              class="zhangsna"
              v-if="oderItem.order.orderStatus == 'DELIVERED'"
            >
              待收货
            </div>
            <div
              class="zhangsna"
              v-if="oderItem.order.orderStatus == 'COMPLETED'"
            >
              已完成
            </div>
            <div
              class="zhangsna"
              v-if="oderItem.order.orderStatus == 'CANCELLED'"
            >
              已取消
            </div>
          </div>
          <div class="ifolist">
            <div class="name">支付方式</div>
            <div class="zhangsna" v-if="!oderItem.order.paymentMethod && oderItem.order.orderPromotionType != 'POINTS'">/</div>
            <div class="zhangsna" v-if="oderItem.order.orderPromotionType == 'POINTS'">积分支付</div>
            <div
              class="zhangsna"
              v-if="oderItem.order.paymentMethod == 'WECHAT'"
            >
              微信
            </div>
            <div
              class="zhangsna"
              v-if="oderItem.order.paymentMethod == 'ALIPAY'"
            >
              支付宝
            </div>
          </div>
          <div class="ifolist">
            <div class="name">支付时间</div>
            <div class="zhangsna" v-if="oderItem.order.paymentTime">
              {{ oderItem.order.paymentTime }}
            </div>
            <div class="zhangsna" v-if="!oderItem.order.paymentTime">/</div>
          </div>
        </div>
      </div>
      <div class="rightss">
        <div class="tops">物流信息</div>
        <div class="rightcenter">
          <!-- <stepcenter></stepcenter -->
          <el-timeline :reverse="reverse" v-if="oderItem.traces">
            <el-timeline-item
              v-for="(activity, index) in oderItem.traces.traces"
              :key="index"
              :timestamp="activity.AcceptTime"
            >
              {{ activity.AcceptStation }}
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </div>
    <div class="tabbers" v-if="isorder != '1' && !oderItem.ispinglun">
      <div class="tabbertop">商品信息</div>
      <div class="tab">
        <el-table :data="oderItem.orderItems" style="width: 100%">
          <el-table-column label="商品">
            <template slot-scope="scope">
              <div class="goodslist">
                <img class="goodsimg" :src="scope.row.image" alt="" />
                <div class="goodsright">
                  <div class="gootitle">
                    {{ scope.row.goodsName }}
                  </div>
                  <div class="skulsi">
                    {{ scope.row.simpleSpecs }}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="skuId" label="商品编号" width="180">
          </el-table-column>
          <el-table-column prop="goodsPrice" label="单价" width="90">
            <template slot-scope="scope">
              <span v-if="scope.row.promotionType == 'POINTS_GOODS'"
                >{{ scope.row.point || 0 }}积分</span
              >
              <span v-else>￥{{ scope.row.goodsPrice || 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="num" label="数量" width="90">
          </el-table-column>
          <el-table-column
            label="操作"
            width="90"
            v-if="oderItem.order.orderStatus == 'DELIVERED'"
          >
            <template slot-scope="scope">
              <div
                class="btns"
                v-if="oderItem.order.orderStatus == 'DELIVERED' && oderItem.order.orderPromotionType != 'POINTS'"
                @click="toshouhou(scope.row)"
              >
                申请售后
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="btooombg" v-if="isorder != '1' && !oderItem.ispinglun">
      <div class="textone">
        <span>商品总价：</span
        ><span
          class="spabs"
          v-if="oderItem.order.orderPromotionType == 'POINTS'"
          >{{ oderItem.order.payPoint || 0 }}积分</span
        >
        <span class="spabs" v-else>￥{{ oderItem.order.goodsPrice || 0 }}</span>
      </div>
      <div class="textone">
        <span>运费(快递)：</span
        ><span class="spabs">￥{{ oderItem.order.freightPrice }}</span>
      </div>
      <div class="textone">
        <span>优惠金额：</span
        ><span class="spabs">￥{{ oderItem.order.discountPrice }}</span>
      </div>
      <div class="textone">
        <span>实付款：</span>
        <span class="spabs" v-if="oderItem.order.orderPromotionType == 'POINTS'"
          >{{ oderItem.order.payPoint || 0 }}积分</span
        >
        <span class="spabs" v-else>￥{{ oderItem.order.flowPrice || 0 }}</span>
      </div>
    </div>
    <div class="pinglun" v-if="stepInfo.step == 4 || isorder == '1'">
      <div
        class="alist"
        v-for="(item, index) in oderItem.orderItems"
        :key="index"
      >
        <div class="googstab">
          <div class="goodstop">
            <span class="diangnda">订单号：{{ oderItem.order.sn }}</span
            ><span class="shijian">{{ item.createTime }}</span>
          </div>
          <div class="goodsbottom">
            <div class="left">
              <img class="imga" :src="item.image" alt="" />
              <div class="title">{{ item.goodsName }}</div>
              <div class="price">￥{{ item.goodsPrice }}</div>
            </div>
            <div class="rightcommnets">
              <div class="coomstop">
                <div class="pingjia">商品评价：</div>
                <div
                  :class="item.actives == inx ? 'goods bags' : 'goods'"
                  v-for="(ims, inx) in cartlsit"
                  :key="inx"
                  @click="haopingzhongping(index, inx)"
                >
                  {{ ims.name }}
                </div>
              </div>
              <div class="righttexts">
                <el-input
                  type="textarea"
                  placeholder="写下你的感受吧~"
                  :value="item.content"
                  @input="inputtexts($event, index)"
                  :rows="4"
                >
                </el-input>
                <div class="btnsads">
                  <div class="shagnchuan">
                    <el-upload
                      :action="uploadUrl"
                      class="upload-demo"
                      ref="upload"
                      name="file"
                      :multiple="true"
                      :headers="headers"
                      :show-file-list="false"
                      :auto-upload="true"
                      :limit="9"
                      :on-success="uploadFile.bind(null, index)"
                      :before-upload="beforeAvatarUpload"
                    >
                      <div class="tupains">
                        <div class="uplids">
                          <img
                            class="imgs"
                            src="../../assets/img/pic.png"
                            alt=""
                          />
                          <div>上传图片</div>
                        </div>
                        <span class="spansaa" v-if="item.imgList.leng < 1"
                          >最多只能上传9张图</span
                        >
                        <img
                          class="imgsaa"
                          v-for="(im, idx) in item.imgList"
                          :key="idx"
                          :src="im"
                          alt=""
                        />
                      </div>
                    </el-upload>
                  </div>
                  <div
                    class="btnsaaaaa"
                    @click="addpinglun(index)"
                    v-if="item.commentStatus == 'UNFINISHED'"
                  >
                    发表评价
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import steps from "./step.vue";
import stepcenter from "./stepcenter.vue";
import { oderListdetail, postmemberEvaluation } from "../../request/homeApi";
import { oupdet } from "@/request/xie";
export default {
  components: {
    steps,
    stepcenter,
  },
  data() {
    return {
      uploadUrl: "",
      cartlsit: [
        {
          name: "好评",
          id: 0,
        },
        {
          name: "中评",
          id: 1,
        },
        {
          name: "差评",
          id: 2,
        },
      ],
      dialogVisible: false,
      reverse: false,
      activities: [
        {
          content: "活动按期开始",
          timestamp: "2018-04-15",
        },
        {
          content: "活动按期开始",
          timestamp: "2018-04-15",
        },
        {
          content: "活动按期开始",
          timestamp: "2018-04-15",
        },
        {
          content: "活动按期开始",
          timestamp: "2018-04-15",
        },
        {
          content: "通过审核",
          timestamp: "2018-04-13",
        },
        {
          content: "创建成功",
          timestamp: "2018-04-11",
        },
      ],
      stepInfo: {
        list: [
          {
            status: "拍下商品",
            statusTime: "",
          },
          {
            status: "付款",
            statusTime: "",
          },
          {
            status: "卖家发货",
            statusTime: "",
          },
          {
            status: "确认收货",
            statusTime: "",
          },
          {
            status: "评价",
            statusTime: "",
          },
        ],
        step: 2,
        width: 11,
      },
      tableData: [],
      orderSn: "",
      oderItem: {},
      headers: {},
      isorder: "",
    };
  },
  mounted() {
    this.orderSn = this.$route.query.orderSn;
    if (this.$route.query.isorder && this.$route.query.isorder == "1") {
      this.isorder = this.$route.query.isorder;
    }
    if (this.$route.query.step) {
      this.stepInfo.step = this.$route.query.step;
    }
    this.oderdetailApi();
    this.uploadUrl = oupdet();
    let token = localStorage.getItem("token");
    this.headers = {
      accessToken: token,
    };
  },
  methods: {
    // 申请售后   
    toshouhou(item) {
      this.$router.push({
        path:
          "/mepages/aftersale?orderSn=" + this.orderSn + "&ids=" + item.goodsId,
      });
    },
    inputtexts(e, index) {
      console.log(e);
      this.oderItem.orderItems[index].content = e;
      this.$forceUpdate();
    },
    // 提交评论
    addpinglun(index) {
      let data = {
        content: this.oderItem.orderItems[index].content,
        goodsId: this.oderItem.orderItems[index].goodsId,
        images: this.oderItem.orderItems[index].imgList.join(","),
        orderItemSn: this.oderItem.orderItems[index].sn,
        skuId: this.oderItem.orderItems[index].skuId,
        grade:
          this.oderItem.orderItems[index].actives == 0
            ? "GOOD"
            : this.oderItem.orderItems[index].actives == 1
            ? "MODERATE"
            : this.oderItem.orderItems[index].actives == 2
            ? "WORSE"
            : "",
      };
      postmemberEvaluation(data)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.oderdetailApi();
          }
        })
        .catch((res) => {
          console.log("请求失败");
        });
    },
    // 点击评论等级
    haopingzhongping(index, inx) {
      this.oderItem.orderItems[index].actives = inx;
      this.$forceUpdate();
    },
    beforeAvatarUpload() {},
    uploadFile(index, param) {
      this.oderItem.orderItems[index].imgList.push(param.result);
      console.log(this.oderItem);
      this.$forceUpdate();
    },
    // 获取订单详情
    oderdetailApi() {
      oderListdetail(this.orderSn)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.oderItem = res.result;
            this.oderItem.ispinglun = false;
            this.oderItem.orderItems.forEach((items) => {
              if (items.grade == "GOOD") {
                items.actives = 0;
              }
              if (items.grade == "MODERATE") {
                items.actives = 1;
              }
              if (items.grade == "WORSE") {
                items.actives = 2;
              }
              items.imgList = [];
              if (items.replyImg) {
                items.imgList = items.replyImg.split(",");
              }

              if (items.commentStatus == "UNFINISHED") {
                this.oderItem.ispinglun = true;
              }
            });
            // 处理进度条
            if (res.result.order.orderStatus == "CANCELLED") {
              this.stepInfo.step = 0;
            }
            if (
              res.result.order.orderStatus == "COMPLETED" &&
              this.oderItem.ispinglun
            ) {
              this.stepInfo.step = 4;
            }
            if (
              res.result.order.orderStatus == "COMPLETED" &&
              !this.oderItem.ispinglun
            ) {
              this.stepInfo.step = 5;
            }
            if (res.result.order.orderStatus == "UNPAID") {
              this.stepInfo.step = 1;
            }
            if (res.result.order.orderStatus == "PAID") {
              this.stepInfo.step = 2;
            }
            if (res.result.order.orderStatus == "DELIVERED") {
              this.stepInfo.step = 3;
            }
            this.stepInfo.list[0].statusTime = res.result.order.createTime.split(' ')[0];
            this.stepInfo.list[1].statusTime = res.result.order.paymentTime.split(' ')[0];
            this.stepInfo.list[2].statusTime = res.result.order.logisticsTime.split(' ')[0];
            this.stepInfo.list[3].statusTime = res.result.order.completeTime.split(' ')[0];
          }
        })
        .catch((res) => {
          console.log("请求失败");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-upload-list {
  display: none;
}
.details {
  background: #ffffff;
  padding: 35px;
  .lsitads {
    height: 310px;
    border: 1px solid #dcdcdc;
    display: flex;
    justify-content: center;
    .detalet {
      width: 40%;
      .tops {
        width: 100%;
        height: 36px;
        line-height: 36px;
        padding-left: 20px;
        background: #efefef;
        color: #535353;
        border-right: 1px solid #dcdcdc;
      }
      .leftbottom {
        width: 100%;
        height: 284px;
        // overflow-y: scroll;
        padding: 15px 20px;
        border-right: 1px solid #dcdcdc;
        .ifolist {
          display: flex;
          justify-content: flex-start;
          font-size: 14px;
          .name {
            width: 70px;
            color: #535353;
          }
          .zhangsna {
            width: 76%;
            color: #818181;
          }
        }
      }
    }
    .rightss {
      width: 60%;
      overflow: hidden;
      .tops {
        width: 100%;
        height: 36px;
        line-height: 36px;
        padding-left: 20px;
        background: #efefef;
        color: #535353;
      }
      .rightcenter {
        width: 100%;
        padding: 10px 5px;
        height: 284px;
        overflow-y: scroll;
      }
    }
  }
}
.tabbers {
  width: 100%;
  margin-top: 17px;
  border: 1px solid #dcdcdc;
  .tabbertop {
    width: 100%;
    height: 36px;
    background: #efefef;
    border-bottom: 1px solid #dcdcdc;
    padding: 0 15px;
    line-height: 36px;
    font-size: 14px;
    color: #535353;
  }
}
.btooombg {
  height: 146px;
  background: #efefef;
  padding: 28px 30px;
  margin-top: 17px;
  .textone {
    text-align: right;
    font-size: 14px;
    color: #535353;
    .spabs {
      display: inline-block !important;
      width: 100px;
    }
  }
}
.goodslist {
  display: flex;
  align-items: center;
  .goodsimg {
    width: 130px;
    height: 72px;
  }
  .goodsright {
    width: 250px;
    height: 72px;
    margin-left: 10px;
    .gootitle {
      width: 100%;
      font-size: 14px;
      color: #535353;
    }
    .skulsi {
      font-size: 14px;
      color: #818181;
    }
  }
}
.pinglun {
  width: 100%;
  margin-top: 50px;
  .alist {
    display: flex;
    align-items: center;

    .googstab {
      width: 100%;
      margin-bottom: 10px;
      border: 1px solid #dcdcdc;
      .goodstop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #818181;
        font-size: 16px;
        padding: 17px 16px;
        border-bottom: 1px solid #dcdcdc;
      }
      .goodsbottom {
        display: flex;
        align-items: center;
        font-size: 16px;
        .left {
          width: 275px;
          height: 264px;
          padding: 24px 28px;
          color: #535353;
          text-align: center;
          border-right: 1px solid #dcdcdc;
          .imga {
            width: 100%;
            height: 133px;
          }
        }
        .rightcommnets {
          margin-left: 20px;
          width: 690px;
          .coomstop {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #818181;
            .goods {
              width: 76px;
              height: 33px;
              text-align: center;
              line-height: 33px;
              background: #ffffff;
              border: 1px solid #dcdcdc;
              cursor: pointer;
              // border-radius: 2px 0px 0px 2px;
            }
            .bags {
              color: #00a3e0;
              border: 1px solid #00a3e0;
            }
          }
          .righttexts {
            margin-top: 17px;
            padding-right: 20px;
            .textarea {
              width: 100%;
              height: 80px;
            }
            .btnsads {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 10px;
              .shagnchuan {
              }
              .btnsaaaaa {
                width: 118px;
                height: 35px;
                text-align: center;
                line-height: 35px;
                background: #00a3e0;
                border-radius: 2px;
                font-size: 16px;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}
.tupains {
  display: flex;
  justify-content: flex-start;
  justify-items: self-end;
  color: #818181;
  font-size: 12px;
  .imgsaa {
    width: 60px;
    height: 60px;
    margin-left: 5px;
  }
  .spansaa {
    margin-top: 40px;
    margin-left: 10px;
  }
  .uplids {
    width: 60px;
    height: 60px;
    background: #feffff;
    border: 1px solid #dcdcdc;
    text-align: center;
    color: #818181;
    font-size: 12px;
    .imgs {
      width: 20px;
      height: 17px;
      margin: 10px 0 10px;
    }
  }
}
.btns {
  font-size: 14px;
  color: #00a3e0;
  cursor: pointer;
}
</style>