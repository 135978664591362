<template>
  <div class="steps-wrap">
    <ul>
      <li v-for="(item,index) in steps" :key="index">
        <span class="time">{{item.time}}</span>
        <div class="circle">
          <!-- <img class="icon" v-if="index===0" src="../../../assets/images/user_seleted.png" /> -->
          <!-- <img
            class="icon"
            v-else-if="index === steps.length-1"
            src="../../../assets/images/user_seleted.png"
          /> -->
          <!-- <i v-else class="circle-icon"></i> -->
        </div>
 
        <span v-html="item.context" class="message"></span>
      </li>
    </ul>
  </div>
</template>
 
<script>
export default {
  props: {
    steps: {
      type: Array,
    }
  }
}
</script>
 
<style lang="scss">
.steps-wrap {
  ul {
    padding: 0 16px;
    li {
      display: flex;
      line-height: 1rem;
      color: #999;
      .time {
        text-align: center;
        width: 80px;
        font-size: 12px;
      }
      .circle {
        position: relative;
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        top: 0;
        .icon {
          width: 100%;
          height: 100%;
        }
        .circle-icon {
          position: relative;
          z-index: 999;
          display: inline-block;
          border-radius: 50%;
          width: 8px;
          height: 8px;
          background-color: #333333;
        }
      }
      .message {
        padding: 0 0 1.6rem 25px;
        font-size: 12px;
        flex: 1;
        border-left: 1px solid #999999;
        margin-left: -8px;
      }
      &:last-child {
        .message {
          border-left: 1px solid transparent;
        }
      }
    }
  }
}
</style>